<template>
    <div class="container-fluid">
        <header class="d-flex flex-wrap bg-white align-items-center justify-content-center justify-content-md-between">
            <HeaderLogo />
            <!-- 상단 메뉴 영역 ===================================== -->
            <TopMenu />
            <!-- // 상단 메뉴 영역 ================================== -->

            <!-- 로그인 처리 영역 ==================================== -->
            <SignInOut ref="sign-in-out" />
            <!-- // 로그인 처리 영역 ================================= -->
        </header>
    </div>
    <div class="row">
        <!-- 컨텐츠 표시 영역 ======================================== -->
        <router-view></router-view>
        <!-- //컨텐츠 표시 영역 ====================================== -->
    </div>
</template>

<script>
	import TopMenu from "./inc/TopMenu";
	import SignInOut from "./inc/SignInOut";
	import Footer from "./inc/Footer";
	import { router } from "@/routes/index.js";
  import global from "global";
  import HeaderLogo from "./inc/HeaderLogo.vue";

	export default
	{
		components:
		{
			TopMenu,
			SignInOut,
			Footer,
      HeaderLogo,
		},
        provide()
        {
            return {
                signIn: this.signIn,
            }
        },
		data() {
			return {
				colLeft: "col-md-3 col-lg-2",
				colRight: ""
			}
		},
		methods:
		{
			// 루트 페이지로 이동
			goRoot()
			{
				router.push({path: "/"});
			},
      signIn()
      {
        this.$refs["sign-in-out"].signIn();
      },
      isKCHFUser() {
        return global.isKCHFUser();
      },
		}
	}
</script>

<style scoped>
.container-fluid {
    padding: 10px 2%;
    background: #fff;
}
.row {
    /* padding: 10px 2%; */
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100%;
}
</style>
