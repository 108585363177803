<template>
  <!-- 하단 표시 영역 -->
  <footer class="d-grid bg-light d-flex justify-content-center p-3 mt-4 sticky-bottom text-center">
    <p>Copyright©{{ new Date().getFullYear() }} Dot Incorporation. All rights reserved.</p>
    <ul>
      <li><a href="https://www.dotincorp.com/kr/page/terms" target="_blank" class="terms">Terms&Condition</a></li>
      <li><a href="https://www.dotincorp.com/kr/page/privacy" target="_blank" class="policy">Privacy Policy</a></li>
    </ul>
  </footer>
  <!-- // 하단 표시 영역 -->
</template>

<script></script>

<style scoped>
footer {
  color: #767474;
  background: #484646;
  font-weight: 500;
}

p, ul {
  margin-bottom: 0;
}

ul {
  padding-left: 24rem;
}

ul:after {
  content: "";
  clear: both;
  display: block;
}

li {
  float: left;
  margin: 0 10px;
  list-style: none;
}

a {
  color: inherit;
}

a:hover, a:active, a:focus {
  opacity: .8;
}

.terms, .policy {
  color: #484646;
  font-weight: bold;
}
</style>
