export default 
{
	// $store.state.변수
	state: () => ({
        COMP_NO: null,
		TOP_MENU_ID: "" // 선택된 상단 메뉴
	}),
	// $store.commit(함수명, 파라미터) 
	mutations: 
	{
        setCompNo(state, value)
        {
            state.COMP_NO = value;
        },
		// 상단에 선택된 메뉴ID 설정
		setTopMenuId(state, value)
		{
			state.TOP_MENU_ID = value;
		},
	},
	// $store.getters.변수
	getters: 
	{
        COMP_NO: (state) =>
        {
            return state.COMP_NO;
        },
		// 상단에 선택된 메뉴ID 조회
		TOP_MENU_ID : (state) =>
		{
			return state.TOP_MENU_ID;
		},
	}
 };