/*
 * =====================================================================
 * 작 업 명  :
 * 파 일 명  : textbook.js
 * 변경이력
 * 2023-08-29 / 배윤석 / 최초작성
 * =====================================================================
 */
import {$axios, $session} from "dot-framework";
import axios from 'axios';
import Swal from "sweetalert2";
import store from "@/store";

const state = () => ({
  title: "",
  dtmNo: "",
  dtmGroupNo: "",
  dtmName: "",
  contents: "",
  userNo: "",
  creator: "",

  characterCount: 0,
  wordCount: 0,

  lastSaveContents: "",

  fileFormat: "",
  imageType: "",

  isAutoSave: false,
  isNewDocument: true,
  menuType: "",
  autosaveTimer: null,

  filePath: defaultFilePath,
});

const defaultData =
`<html>
  <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title></title>
  </head>
  <body>
    <p></p>
  </body>
</html>`;

const defaultFilePath = [{
  DTM_GROUP_LEVEL: 1,
  DTM_GROUP_NAME: "/",
  DTM_GROUP_NO: "",
  PARENT_GROUP_NO: "",
}];

// actions : $store.dispatch(함수명, 파라미터)
const actions = {
  /**
   * 데이터 초기화
   */
  initData({ commit }) {
    commit("setTitle", "");
    commit("setDtmNo", "");
    commit("setDtmGroupNo", "");
    commit("setDtmName", "");
    commit("setContents", "");
    commit("setCharacterCount", 0);
    commit("setWordCount", 0);
    commit("setIsAutoSave", false);
    commit("setIsNewDocument", true);
    commit("setMenuType", "new");
    commit("setCreator", $session.getUserInfo().NAME);
  },
  initFilePath({ commit }) {
    commit("setFilePath", defaultFilePath);
  },
  async saveAs({ commit, state }, contents) {
    if (state.dtmGroupNo === "") {
      state.dtmGroupNo = "ROOT";
    }
    if (state.title === "") {
      commit("setTitle", "Untitled");
    }
    const formData = new FormData();
    formData.append("HTML_STRING", contents);
    formData.append("DTM_NAME", state.title);
    formData.append("DTM_GROUP_NO", state.dtmGroupNo);
    formData.append("CREATOR", state.creator);
    formData.append("USER_NO", state.userNo);
    formData.append("DEVICE_KIND", "300");

    const url = `/drive-app/v1/doc/html/from-string`;

    try {
      const response = await $axios.post(url, formData);
      if (response.status === 201) {
        commit("setDtmNo", response.data.DTM_NO);
        commit("setDtmGroupNo", response.data.DTM_GROUP_NO);
        commit("setTitle", response.data.DTM_NAME);
      }

      commit("setLastSaveContents", contents);

      return contents;
    } catch (error) {
      console.log("error :>> ", error);
    }
  },
  async modifyDocument({ commit, state }, contents) {
    const params = {
      HTML_STRING: contents,
      DTM_NAME: state.title === "" ? "Untitled" : state.title,
      DTM_NO: state.dtmNo,
      DTM_GROUP_NO: state.dtmGroupNo, // "0 = System; 1 = Braille"
      USER_NO: state.userNo,
      CREATOR: state.creator,
    };

    const url = `/drive-app/v1/doc/html`;

    try {
      const response = await $axios.put(url, params);
      if (response.status === 201) {
        commit("setDtmNo", response.data.DTM_NO);
        commit("setDtmGroupNo", response.data.DTM_GROUP_NO);
        commit("setTitle", response.data.DTM_NAME);
      }
      commit("setLastSaveContents", contents);

      return contents;
    } catch (error) {
      console.log("error :>> ", error);
    }
  },
  modifyHtmlTitle({ state }, attr) {
    let value = attr.get("$fullPageDocument");
    let title = state.title;
    if (!title) {
      title = "Untitled";
    }
    value = value.replace(/<title>.*?<\/title>/, `<title>${title}</title>`);
    attr.set("$fullPageDocument", value);
  },

  async htmlConvert({ commit, state }, { file, pdfEngine }) {
    if (!file) return false;

    const fileName = file.name;
    const title = fileName.substring(0, fileName.lastIndexOf("."));
    const currentDtmGroupNo = store.getters["drive/getCurrentGroupNo"];
    const siteNo = $session.getSiteNo();

    const formData = new FormData();

    formData.append("FILE", file);
    formData.append("IMAGE_TYPE", "file");
    formData.append("DTM_NAME", title);
    formData.append("DTM_GROUP_NO", currentDtmGroupNo);
    formData.append("USER_NO", $session.getUserNo());
    formData.append("DEVICE_KIND", "300");
    formData.append("PDF_ENGINE", pdfEngine);
    formData.append("SITE_NO", siteNo);

      Swal.fire({
        title: "",
        html: " ",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

    const url = "/drive-app/v1/doc/html-convert";
    try {
      const response = await axios.post(url, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          Swal.update({
            html: `<swalCustomTag><div style="display: grid; grid-template-columns: repeat(4, 1fr);"><b style="grid-column: 2/3; text-align: left">●</b><b style="grid-column: 3/5; text-align: left">${percentCompleted}%</b></div></swalCustomTag>`,
            customClass: "swal-wide"
          });
        }
      });
      await store.dispatch("drive/reloadItems", { parentGroupNo: currentDtmGroupNo });
      return response.status;
    } catch (error) {
      return error.response.status;
    }
  },

  async getConversionCompletePopup({ commit, state }, userNo) {
    const url = `/drive-app/v1/doc/convert-status-popup?USER_NO=${userNo}`;
    const response = await $axios.get(url, null, true);
    if (response.status === 200 && response.data && Array.isArray(response.data.ITEMS)) {
      return response.data.ITEMS.length > 0 ? response.data.ITEMS : null;
    }
    return null;
  },

  async popupDisplayComplete({ commit, state }, dtmNo) {
    const params = {
      DTM_NO: dtmNo
    };
    const url = `/drive-app/v1/doc/convert-status-popup`;
    await $axios.put(url, params);
  },

  async getLocalFile({ commit, state }, event) {
    const fileName = event.target.files[0].name;
    const fileSeparator = fileName.lastIndexOf(".");
    const title = fileName.substring(0, fileSeparator);
    const pdfEngine = process.env.VUE_APP_PDF_ENGINE

    const el2 = document.querySelector('[contenteditable]');
    el2.contentEditable='false'
    const formData = new FormData();

    if (event.target.files[0] !== undefined) {
      formData.append("FILE", event.target.files[0]);
      formData.append("IMAGE_TYPE", state.imageType);
      formData.append("DTM_NAME", title);
      formData.append("DTM_GROUP_NO", "ROOT");
      formData.append("USER_NO", state.userNo);
      formData.append("DEVICE_KIND", "300");
      formData.append("PDF_ENGINE", pdfEngine);

      const url = `/drive-app/v1/doc/html-convert`;
      const response = await $axios.post(url, formData);

      if (response.status === 200 || response.status === 201) {
        await commit("setDtmNo", response.data.DTM_NO);
        await commit("setDtmGroupNo", response.data.DTM_GROUP_NO);

        const url = `/drive-app/v1/doc/convert-status?DTM_NO=${state.dtmNo}`;
        let isSuccessful = false;

        while (!isSuccessful) {
          const response = await $axios.get(url, null, true);
          if (response.status === 200) {
            if (response.data.STATUS === "SUCCESS") {
              isSuccessful = true;
              commit("setIsNewDocument", false);
              const el2 = document.querySelector('[contenteditable]');
              el2.contentEditable='true'
            } else if (response.data.STATUS === "ONGOING") {
              await new Promise(resolve => setTimeout(resolve, 10000));
            } else if (response.data.STATUS === "FAILED") {
              response.status = 400;
              const el2 = document.querySelector('[contenteditable]');
              el2.contentEditable='true'
              return response;
            }
          } else {
            return response
          }
        }
      }
      return response;
    }
  },
  async getHtml({ commit, state }) {
    const url = `/drive-app/v1/doc/html?DTM_NO=${state.dtmNo}`;

    const response = await $axios.get(url, null, true);
    if (response.status === 200) {
      commit("setTitle", response.data.DTM_NAME);
      commit("setDtmNo", state.dtmNo);
      commit("setDtmGroupNo", state.dtmGroupNo);
      commit("setDtmName", response.data.DTM_NAME);
      commit("setCreator", response.data.CREATOR);
      commit("setContents", response.data.CONTENTS);
    }
    return response;
  },
  async exportFile({ commit }, formData) {
    const url = `/drive-app/v1/doc/daisy-convert`;
    return await $axios.post(url, formData);
  },
  async getDaisy({ commit }, dtmNo) {
    const url = `/drive-app/v1/doc/daisy?DTM_NO=${dtmNo}`;
    return await $axios.get(url);
  },
  async exportConvertStatusCheck({ commit }, dtmNo) {
    const url = `/drive-app/v1/doc/convert-status?DTM_NO=${dtmNo}`;
    return await $axios.get(url);
  },
  convertBase64({ commit }, strUrl) {
    const formData = new FormData();
    formData.append("imageUrl", strUrl);
    const url = `/drive-app/v1/doc/image-url-to-base64`;

    return $axios.post(url, formData);
  },
  clearAutosaveTimer({ state }) {
    if (state.autosaveTimer !== null) {
      clearTimeout(state.autosaveTimer);
      state.autosaveTimer = null;
    }
  },
  isAutosaveImpossible({ state }) {
    const validTypes = ["new", "open", "import", "autosave"];
    return validTypes.includes(state.menuType);
  },
  removeEditorIMEBuffer() {
    const content = document.activeElement;
    content.blur();
    content.focus();
    window.getSelection().removeAllRanges();
  },
  autosave({ commit, state, dispatch }, editor) {
    // commit(`setIsAutoSave`, true);
    //
    // const autosaveTimer = setTimeout(() => {
    //   dispatch("removeEditorIMEBuffer");
    //
    //   const editorData = editor.getData() ?? "";
    //   const lastSavedData = state.lastSaveContents ?? "";
    //   const hasChanges = lastSavedData !== editorData;
    //   if (!hasChanges) {
    //     commit(`setIsAutoSave`, false);
    //     return;
    //   }
    //
    //   const currentDataTrimmed = editorData.replace(/ /g, "");
    //   const isContentDifferent = defaultData.replace(/ /g, "") !== currentDataTrimmed;
    //   if (!isContentDifferent) return;
    //
    //   commit(`setIsAutoSave`, true);
    //   commit(`setMenuType`, "autosave");
    //   const isNewDocument = state.isNewDocument;
    //   const documentAttributes = editor.model.document.getRoot()._attrs;
    //
    //   const actionPrefix = isNewDocument ? "saveAs" : "modifyDocument";
    //   dispatch(`modifyHtmlTitle`, documentAttributes);
    //   dispatch(`${actionPrefix}`, editorData);
    //   if (isNewDocument) {
    //     commit(`setIsNewDocument`, false);
    //   }
    //   commit(`setIsAutoSave`, false);
    //   clearTimeout(state.autosaveTimer);
    //   commit(`setAutosaveTimer`, null);
    // }, 3000);

    // commit(`setAutosaveTimer`, autosaveTimer);

    commit(`setIsAutoSave`, false);
  },
  deleteDoc({state}) {
    const url = `/drive-app/v1/dtm/images/${state.dtmNo}`;
    return $axios.delete(url, null, true);
  }
};

// getters : $store.getters.변수
const getters = {
  getTitle: (state) => {
    return state.title;
  },
  getDtmNo: (state) => {
    return state.dtmNo;
  },
  getDtmGroupNo: (state) => {
    return state.dtmGroupNo;
  },
  getDtmName: (state) => {
    return state.dtmName;
  },
  getContents: (state) => {
    return state.contents;
  },
  getUserNo: (state) => {
    return state.userNo;
  },
  getCreator: (state) => {
    return state.creator;
  },
  getLastSaveContents: (state) => {
    return state.lastSaveContents;
  },
  getCharacterCount: (state) => {
    return state.characterCount;
  },
  getWordCount: (state) => {
    return state.wordCount;
  },
  getFileFormat: (state) => {
    return state.fileFormat;
  },
  getImageType: (state) => {
    return state.imageType;
  },
  getIsAutoSave: (state) => {
    return state.isAutoSave;
  },
  getAutosaveTimer: (state) => {
    return state.autosaveTimer;
  },
  getIsNewDocument: (state) => {
    return state.isNewDocument;
  },
  getMenuType: (state) => {
    return state.menuType;
  },
  getFilePath: (state) => {
    return state.filePath;
  },
  getDefaultData: () => {
    return defaultData;
  },
};

// mutations : $store.commit(함수명, 파라미터)
const mutations = {
  setTitle(state, title) {
    title = title.replace(/[\\\/:*?"<>|]/g, '');
    state.title = title;
  },
  setDtmNo(state, dtmNo) {
    state.dtmNo = dtmNo;
  },
  setDtmGroupNo(state, dtmGroupNo) {
    state.dtmGroupNo = dtmGroupNo;
  },
  setDtmName(state, dtmName) {
    state.dtmName = dtmName;
  },
  setContents(state, contents) {
    state.contents = contents;
  },
  setUserNo(state, userNo) {
    state.userNo = userNo;
  },
  setCreator: (state, creator) => {
    state.creator = creator;
  },
  setLastSaveContents(state, lastSaveContents) {
    state.lastSaveContents = lastSaveContents;
  },
  setCharacterCount(state, characterCount) {
    state.characterCount = characterCount;
  },
  setWordCount(state, wordCount) {
    state.wordCount = wordCount;
  },
  setFileFormat(state, fileFormat) {
    state.fileFormat = fileFormat;
  },
  setImageType(state, imageType) {
    state.imageType = imageType;
  },
  setIsAutoSave(state, isAutoSave) {
    state.isAutoSave = isAutoSave;
  },
  setAutosaveTimer(state, autosaveTimer) {
    state.autosaveTimer = autosaveTimer;
  },
  setIsNewDocument(state, isNewDocument) {
    state.isNewDocument = isNewDocument;
  },
  setMenuType(state, menuType) {
    state.menuType = menuType;
  },
  setFilePath(state, filePath) {
    state.filePath = filePath;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
