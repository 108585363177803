import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue3-cookies";
import { router } from "@/routes/index.js";
import { i18n } from "@/i18n/index.js";
import global from "global";
import store from "@/store/index.js";
import { $router, $axios } from "dot-framework";
import VueGtag from "vue-gtag";
import CKEditor from "@ckeditor/ckeditor5-vue";
/**
 * Bootstrap 설정
 */
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js"
import 'bootstrap-icons/font/bootstrap-icons.css';
/**
 * SweetAlert2 설정
 */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// ---------------------------------
// app 선언
// ---------------------------------
const app = createApp(App);
app.use(VueAxios, axios);     // axios 사용
app.use(VueCookies);          // 쿠키 사용
app.use(router);              // 라우터 사용
app.use(store);               // vuex 사용
app.use(i18n);                // 다국어 사용
app.use(VueSweetalert2);      // SweetAlert2 사용
app.use(CKEditor);            // CKEditor 사용
if (process.env.VUE_APP_MODE === "real") {
  app.use(VueGtag, {    // Google Analytics 사용
    config: {id: "G-GKWZ7NS7BZ"}
  }, router);
}
app.config.devtools = true
app.mount("#app");

// ---------------------------------
// 라우트 이동처리 및 axios 인터셉터 처리
// ---------------------------------
$router.setBeforeEach(router);
$axios.interceptorsRequest();
$axios.interceptorsResponse();

// ---------------------------------
// 통합인증 및 메뉴/권한 설정
// ---------------------------------
global.setSession();
