export default [
	{
		name: "login",
		path: "/login",
		component: () => import("@/views/login/Login"),
		meta: { layout: "BlankLayout", leftMenu: "false" }
    },
	{
		name: "login-callback",
		path: "/login-callback",
		component: () => import("@/views/login/LoginCallback"),
		meta: { layout: "BlankLayout", leftMenu: "false" }
    }
];