import { DotpadSDK } from "@/components/dtms-editor-v2.0/js/DotpadSDK.js";
import { Group, Rect, Line, Canvas, StaticCanvas } from "fabric";

const dotpadSDK = new DotpadSDK();
const NUMBER_OF_HORIZONTAL_PINS = 60;
const NUMBER_OF_VERTICAL_PINS = 40;

function getPixelArray(item) {
    const graphicData = item.graphic.data; // 600자 Hex String
    const byteArr = dotpadSDK.hexToBytes(graphicData); // 300 Bytes Array
    const pixelArr = [];

    for (let i = 0; i < byteArr.length; i++) {
        const start_index = Math.floor(i / 30) * 60 * 4 + (i % 30) * 2;
        pixelArr[start_index] = !!(byteArr[i] & (0x01 << 0));
        pixelArr[start_index + 60] = !!(byteArr[i] & (0x01 << 1));
        pixelArr[start_index + 120] = !!(byteArr[i] & (0x01 << 2));
        pixelArr[start_index + 180] = !!(byteArr[i] & (0x01 << 3));
        pixelArr[start_index + 1] = !!(byteArr[i] & (0x01 << 4));
        pixelArr[start_index + 61] = !!(byteArr[i] & (0x01 << 5));
        pixelArr[start_index + 121] = !!(byteArr[i] & (0x01 << 6));
        pixelArr[start_index + 181] = !!(byteArr[i] & (0x01 << 7));
    }

    return pixelArr;
}

// 캔버스 생성
function createCanvas(canvasId, canvasWidth, canvasHeight) {
    const canvas = new Canvas(canvasId);
    canvas.setDimensions({ width: canvasWidth, height: canvasHeight });;

    return canvas;
}

function createStaticCanvas(canvasId, canvasWidth, canvasHeight) {
    const staticCanvas = new StaticCanvas(canvasId);
    staticCanvas.setDimensions({ width: canvasWidth, height: canvasHeight });

    return staticCanvas;
}

// 캔버스에 그리드 그리기
function drawGridOnTheCanvas(canvasWidth, canvasHeight) {
    const gridLines = [];
    const gridOptions = {selectable: false, stroke: '#cccccc'};

    for (let row = 0; row < NUMBER_OF_VERTICAL_PINS; row++) {
        const y = (row / NUMBER_OF_VERTICAL_PINS) * canvasHeight;
        gridLines.push(new Line([0, y, canvasWidth, y], gridOptions));
    }

    for (let column = 0; column < NUMBER_OF_HORIZONTAL_PINS; column++) {
        const x = (column / NUMBER_OF_HORIZONTAL_PINS) * canvasWidth;
        gridLines.push(new Line([x, 0, x, canvasHeight], gridOptions));
    }

    return new Group(gridLines, {
        selectable: false,
        evented: false,
    });
}

// 캔버스에 촉각 정보 그리기
function drawTactileDataOnTheCanvas(pixelArr, canvasWidth, canvasHeight) {
    const pinW = canvasWidth / NUMBER_OF_HORIZONTAL_PINS;
    const pinH = canvasHeight / NUMBER_OF_VERTICAL_PINS;
    const array = [];
    let index = 0;

    for (let i = 0; i < NUMBER_OF_VERTICAL_PINS; i++) {
        for (let j = 0; j < NUMBER_OF_HORIZONTAL_PINS; j++) {
            if (pixelArr[index]) {
                const pin = new Rect({
                    fill: 'black',
                    left: pinW * j,
                    top: pinH * i,
                    width: pinW,
                    height: pinH,
                    hasBorders: false,
                    hasControls: false,
                    hasRotatingPoint: false,
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    hoverCursor: "default",
                });
                array.push(pin);
            }
            index++;
        }
    }

    return new Group(array);
}

function getVoxel() {
  return {
    NUMBER_OF_HORIZONTAL_PINS,
    NUMBER_OF_VERTICAL_PINS,
  }
}

// 텍타일 데이터의 좌표값으로 인덱스를 구하는 함수
function calculateIndexFromRect(rect, canvasWidth, canvasHeight) {
  const pinW = canvasWidth / NUMBER_OF_HORIZONTAL_PINS;
  const pinH = canvasHeight / NUMBER_OF_VERTICAL_PINS;

  // rect의 좌표를 가져옴
  const left = rect.left;
  const top = rect.top;

  // 해당 좌표의 행과 열을 계산
  const row = Math.floor(top / pinH);
  const col = Math.floor(left / pinW);

  // 현재 인덱스 번호 계산
  const index = row * NUMBER_OF_HORIZONTAL_PINS + col;

  return index;
}

export default {
    createCanvas,
    createStaticCanvas,
    drawGridOnTheCanvas,
    drawTactileDataOnTheCanvas,
    getPixelArray,
    getVoxel,
}
