export default [
	{
		path: "/test",
		component: () => import("@/views/test/Index"),
		meta: { layout: "WideLayout" }
    },
	{
		path: "/test/menu-reload",
		component: () => import("@/views/test/MenuReload"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/pagination",
		component: () => import("@/views/test/Pagination"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/save-dtm-file-as-dtms",
		component: () => import("@/views/test/SaveDtmFileAsDtms"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/save-dtms-file-as-dtm",
		component: () => import("@/views/test/SaveDtmsFileAsDtm"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/canvas-template",
		component: () => import("@/views/test/CanvasTemplate"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/openapi",
		component: () => import("@/views/test/OpenAISample.vue"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/braille",
		component: () => import("@/views/test/Braille.vue"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/session",
		component: () => import("@/views/test/Session.vue"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/websocket",
		component: () => import("@/views/test/WebSocket"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/middleware",
		component: () => import("@/views/test/Middleware"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/pdf-convert-demo",
		component: () => import("@/views/test/PdfConvertDemo.vue"),
		meta: { layout: "WideLayout" }
	},
];
