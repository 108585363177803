const state = () => ({
  maxPage: 100,
});

// getters
const getters = {
  maxPage: state => state.maxPage,
};

// mutations
const mutations = {};

// actions
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
